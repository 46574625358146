<template>
  <v-footer app color="grey lighten-1">
    <v-spacer></v-spacer>
    <span class="overline"
      >&copy; {{ new Date().getFullYear() }} SECRETARÍA GENERAL - Administrador
      de servicios V.{{ systemVersion }}</span
    >
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "mainFooter",
  computed: {
    ...mapGetters(["systemVersion"]),
  },
  data: () => ({
    loading: false,
  }),
};
</script>