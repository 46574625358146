<template>
  <v-list nav class="py-0 menu">
    <template v-for="(item, index) in menu">
      <v-list-item
        v-if="item.route"
        :key="index"
        link
        @click="redirect(item.route)"
      >
        <!-- <v-list-item-avatar>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-avatar> -->

        <v-list-item-content class="menu-item">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "leftMenu",
  computed: {
    ...mapGetters(["userName"]),
  },
  data() {
    return {
      menu: [
        { title: "URES", route: "/main/ures/0" },
        { title: "Usuarios", route: "/main/usuarios" },
      ],
    };
  },
  methods: {
    redirect(route) {
      if (this.$route.path != route) this.$router.push(route);
      this.$emit("cerrarMenu");
    },
  },
};
</script>

<style>
.menu{
    margin-top: 20px;
}
.menu-item {
  margin-left: 20px;
}
</style>
