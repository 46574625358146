<template>
  <v-main>
    <v-container>
      <v-layout justify-center>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-card
            class="mx-auto"
            :loading="loading"
            @loadingStart="loading = true"
            @loadingStop="loading = false"
          >
          <v-toolbar color="secondary" dense dark flat>{{userName}}</v-toolbar>
            <router-view></router-view>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "mainContent",
  computed: {
    ...mapGetters(["userName"]),
  },
  data: () => ({
    loading: false,
  }),
};
</script>