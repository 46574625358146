<template>
  <v-app style="background-color: #eceff1">
    <notifications group="gral_notif" position="bottom right" :width="400" />
    <v-app-bar color="primary" app dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="headline text-uppercase">
        <span>Administrador de servicios</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon outlined @click="cerrarSesion()"
        ><v-icon>mdi-power</v-icon></v-btn
      >
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      color="primary"
      :expand-on-hover="false"
      :mini-variant="false"
      :right="false"
      app
      temporary
      dark
    >
      <mainMenuComponent :menu="menu" @cerrarMenu="drawer = false" />
    </v-navigation-drawer>

    <main-content />

    <main-footer />
  </v-app>
</template>

<script>
import { getSession } from "./main.service";

import mainMenuComponent from "./mainMenu";
import MainContent from "./mainContent";
import MainFooter from "./mainFooter";

export default {
  name: "mainView",
  components: {
    mainMenuComponent,
    MainContent,
    MainFooter
  },
  async created() {
    const token = localStorage.getItem("sg-token");
    if (!token) this.$router.push("/login");
    this.$store.dispatch("setSessionToken", token);
    try {
      this.loading = true;
      const serverResponse = await getSession(token);
      if (!serverResponse.ok) this.$router.push("/apps");
      this.$store.dispatch("setCodigoUsuario", serverResponse.codigo);
      this.$store.dispatch("setUserName", serverResponse.nombre);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.$notificarError(error);
      this.$router.push("/apps");
    }
  },
  data: () => ({
    loading: false,
    drawer: false,
    menu: [],
  }),
  methods: {
    cerrarSesion() {
      localStorage.removeItem("sg-token");
      this.$store.dispatch("setSessionToken", null);
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>